import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import Privacy from "./Components/PrivacyPolicy/Privacy";
import Home from "./Components/Home/Home";
import Terms from "./Components/Terms/Terms";

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/terms" element={<Terms />} />
                    </Routes>
                </header>
            </div>
        </Router>
    );
}

export default App;
