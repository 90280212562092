import { useEffect } from "react";

const Contact = () => {

    useEffect(() => {
        window.location.href = "mailto:developer@chigo.app";
        setTimeout(() => {
            window.location.href = "/";
        }, 1000);
    });

    return(<p>Contact Us</p>);
};

export default Contact;