import styles from "./Home.module.scss";
import TrainIcon from "../../Assets/trainLight.png";
import AppStoreSvg from "../../Assets/appStoreDark.svg";

const Home: React.FC = () => {
    return (
        <div className={styles.home}>
            <h1>Download the ChiGo App Today!</h1>
            <div className={styles.icon}>
                <img src={TrainIcon} alt="Train Icon" />
            </div>
            <div>
                <img style={{cursor: "pointer"}} src={AppStoreSvg} onClick={() => window.open('https://apps.apple.com/us/app/chigo-chicago-trains-buses/id6739185774', '_blank')} alt="App Store" />
            </div>
            <div className={styles.links}>
                <a href="/privacy">Privacy Policy</a>
                <a href="/terms">Terms of Service</a>
                <a href="/contact">Contact Us</a>
            </div>
        </div>
    );
};

export default Home;
